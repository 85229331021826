import { template as template_565abbe4febd47f894af1283f637f8af } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_565abbe4febd47f894af1283f637f8af(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
