import { template as template_bdf4a7b5ec53430ea554fd9ae07fd04e } from "@ember/template-compiler";
const FKControlMenuContainer = template_bdf4a7b5ec53430ea554fd9ae07fd04e(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
