import { template as template_2ab4c24dcae74547add4a43372195605 } from "@ember/template-compiler";
const FKLabel = template_2ab4c24dcae74547add4a43372195605(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
